<script lang="ts">
  import ConfirmDeleteModal, {
    confirmDeleteModal,
  } from "./ConfirmDeleteModal.svelte";
  import ErrorModal, { errorModal } from "./ErrorModal.svelte";
  import SetGroupModal, { setGroupModal } from "./SetGroupModal.svelte";
  import MessageModal, { messageModal } from "./MessageModal.svelte";
  import CropperModal, { cropperModal } from "./CropperModal.svelte";
  import ProceedModal, { proceedModal } from "./ProceedModal.svelte";
  import RateLimitModal, {
    rateLimitModal,
  } from "$lib/components/modals/RateLimitModal.svelte";
  import ImageViewModal, { imageViewModal } from "./ImageViewModal.svelte";
  import { beforeNavigate } from "$app/navigation";

  const stores = {
    error: errorModal,
    confirmDelete: confirmDeleteModal,
    setGroup: setGroupModal,
    message: messageModal,
    cropper: cropperModal,
    proceed: proceedModal,
    rateLimit: rateLimitModal,
    imageView: imageViewModal,
  } as const;
  type ModalKey = keyof typeof stores;
  const modalKeys = Object.keys(stores) as ModalKey[];
  let modalStack = [] as ModalKey[];
  const showState = Object.fromEntries(
    modalKeys.map((key) => [key, { show: false, render: false }])
  ) as Record<ModalKey, { show: boolean; render: boolean }>;

  for (let key of modalKeys) {
    stores[key].subscribe(subWithKey(key as ModalKey));
  }

  beforeNavigate(() => {
    for (let key of modalKeys) {
      stores[key].closeModal();
    }
  });

  function subWithKey(key: ModalKey) {
    return ({ show, render }) => {
      if (render && !showState[key].render) {
        if (modalStack.length)
          modalStack.forEach((k) => stores[k].libraryHide());
        modalStack.push(key);
        modalStack = modalStack;
      }
      if (!render && showState[key].render) {
        modalStack = modalStack.filter((k) => k !== key);
        const lastModal = stores[modalStack[modalStack.length - 1]];
        lastModal?.libraryShow();
      }

      showState[key] = { show: show, render: render };
    };
  }

  export let showAnyModal = false;
  $: showAnyModal = Object.values(showState).some(({ show }) => show);
</script>

{#if $rateLimitModal.render && $rateLimitModal.data}
  <RateLimitModal data={$rateLimitModal.data} />
{/if}
{#if $errorModal.render && $errorModal.data}
  <ErrorModal data={$errorModal.data} />
{/if}
{#if $confirmDeleteModal.render && $confirmDeleteModal.data}
  <ConfirmDeleteModal data={$confirmDeleteModal.data} />
{/if}
{#if $setGroupModal.render && $setGroupModal.data}
  <SetGroupModal data={$setGroupModal.data} />
{/if}
{#if $messageModal.render && $messageModal.data}
  <MessageModal data={$messageModal.data} />
{/if}
{#if $proceedModal.render && $proceedModal.data}
  <ProceedModal data={$proceedModal.data} />
{/if}
{#if $imageViewModal.render && $imageViewModal.data}
  <ImageViewModal data={$imageViewModal.data} />
{/if}
{#if $cropperModal.render && $cropperModal.data}
  <CropperModal data={$cropperModal.data} />
{/if}
{#if showAnyModal}
  <div class="modal-backdrop fade show" />
{/if}
