<script lang="ts" context="module">
  import { derived } from "svelte/store";
  import { navigating } from "$app/stores";
  let lastUrl = "";
  export const lastPage = derived(
    navigating,
    ($navigating) => (lastUrl = $navigating?.from?.url.pathname || lastUrl)
  );
</script>

<script lang="ts">
  import { page, updated } from "$app/stores";
  import { afterNavigate, beforeNavigate } from "$app/navigation";
  import { Navbar } from "$lib/components";
  import ModalLibrary from "$lib/components/modals/ModalLibrary.svelte";
  import { layout } from "$lib/stores";
  import { onMount } from "svelte";
  import { PUBLIC_DEV } from "$env/static/public";
  import { browser, version } from "$app/environment";

  type BannerTimer = {
    hideBannerText: string;
    hideAfter: number;
  };

  const bannerEnabled = true; // Enable/disable banner entirely
  const bannerText = `New Terms of Service and Privacy Policy, please read!`;
  const bannerHref = "/policy/terms-of-service";
  const bufferTime = 1000 * 60 * 60 * 24; // 1 day
  const autoHideTime = 1000 * 60 * 60 * 24 * 7; // 1 week

  if (PUBLIC_DEV === "true" && browser)
    console.info("Running in dev mode, all errors logged to sentry.");

  beforeNavigate(async ({ willUnload, to }) => {
    try {
      if (document.readyState !== "complete") {
        await new Promise((resolve) =>
          window.addEventListener("load", resolve)
        );
      }
      if ($updated && !willUnload && to?.url) {
        if ("serviceWorker" in navigator) {
          await fetch("/worker/terminate", { method: "POST" });
          const reg = await navigator.serviceWorker.getRegistration();
          if (reg) {
            await reg.update();
          } else {
            console.error("Service worker not registered, registering now.");
            await navigator.serviceWorker.register("/service-worker.js");
          }
        }
        location.href = to.url.href;
      }
    } catch (e) {
      console.error("Service worker registration failed:", e);
    }
  });

  onMount(async () => {
    try {
      if (document.readyState !== "complete") {
        await new Promise((resolve) =>
          window.addEventListener("load", resolve)
        );
      }
      if ("serviceWorker" in navigator && browser) {
        const lastVersion = await fetch("/worker/version").then((res) =>
          res.ok ? res.text() : ""
        );
        if (!lastVersion)
          await navigator.serviceWorker.register("/service-worker.js");
        if (version !== lastVersion) {
          console.info("New version detected, updating service worker.");
          await Promise.all([
            fetch("/worker/terminate", { method: "POST" }),
            navigator.serviceWorker
              .getRegistration()
              .then((reg) => reg?.update()),
          ]);
        }
      }
    } catch (e) {
      console.error("Service worker registration failed:", e);
    }
  });

  let toggle = false;
  let underline = "none";
  let showAnyModal = false;
  let screenSize;

  $: if (toggle) {
    setTimeout(addCloser, 0);
  }

  afterNavigate(initBanner);

  function initBanner() {
    if (!getBannerTimer()) setBannerTimer(autoHideTime);
    if ($page.url.pathname === bannerHref) return hideBanner();
    // Show banner if user hasn't hid it this session
    $layout.showBanner = !(getSessionHideBanner() || shouldHideAfterTimer());
  }

  function hideBanner() {
    $layout.showBanner = false;
    sessionStorage.setItem("hideBanner", bannerText);
    const showUntil = getBannerTimer();
    if (!showUntil || showUntil.hideAfter > Date.now() + bufferTime)
      setBannerTimer(bufferTime);
  }

  function setBannerTimer(time: number) {
    localStorage.setItem(
      "bannerTimer",
      JSON.stringify({
        hideBannerText: bannerText,
        hideAfter: Date.now() + time,
      })
    );
  }

  function shouldHideAfterTimer(): boolean {
    const showUntil = getBannerTimer();
    return showUntil !== null && Date.now() > showUntil.hideAfter;
  }

  function getSessionHideBanner(): boolean {
    return sessionStorage.getItem("hideBanner") === bannerText;
  }

  /**
   * Returns the showUntil object if it exists and bannerText matches current state, otherwise null
   */
  function getBannerTimer(): BannerTimer | null {
    const showUntilLocal = localStorage.getItem("bannerTimer");
    if (!showUntilLocal) return null;
    const showUntil = JSON.parse(showUntilLocal);
    if (
      typeof showUntil.hideBannerText !== "string" ||
      typeof showUntil.hideAfter !== "number" ||
      showUntil.hideBannerText !== bannerText
    )
      return null;
    return showUntil;
  }

  function addCloser() {
    if (screenSize < 992) {
      addEventListener("click", closeSidebar);
    }
  }

  function closeSidebar(e) {
    if (e.target?.classList.contains("keep-sidebar-open")) return;
    if (screenSize < 992) {
      toggle = false;
    }
    removeEventListener("click", closeSidebar);
  }
</script>

<svelte:window bind:innerWidth={screenSize} />

<div
  id="app"
  class:sidenav-toggled={toggle}
  class:modal-open={showAnyModal}
  data-sveltekit-preload-data
  style:overflow={showAnyModal ? "hidden" : ""}
>
  <Navbar title={"Tupperbox"} on:press={() => (toggle = !toggle)} />
  {#if bannerEnabled && $layout.showBanner}
    <a
      class="alert-banner"
      href={bannerHref}
      on:mouseover={() => (underline = "underline")}
      on:mouseleave={() => (underline = "none")}
      on:focus={() => null}
    >
      <i class="far fa-times-circle invisible" />
      <div>{bannerText}</div>
      <button class="btn" on:click|preventDefault={hideBanner} type="button">
        <i class="far fa-times-circle" />
      </button>
    </a>
  {/if}
  <slot />
  <ModalLibrary bind:showAnyModal />
</div>

<style lang="scss">
  .alert-banner {
    position: relative;
    top: $topnav-base-height;
    background-color: $orange;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: large;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    i {
      font-size: large;
      padding: 0 1rem;
    }
  }
  .alert-banner:focus {
    outline: none;
    > div {
      outline: $white solid 2px;
    }
  }
  i {
    padding: 2px;
  }
  button:focus i {
    outline: $white solid 2px;
    border-radius: 1000px;
  }
  a:hover {
    text-decoration: underline;
  }
</style>
