<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { userPromise } from "$lib/stores";

  const dispatch = createEventDispatcher();

  export let title;
  import { page } from "$app/stores";
  import { Spinner } from "$lib/components";
  import { getUserAvatarURL } from "$lib/utils/discord";
  import { redirectToLogin } from "$lib/api";

  $: segment = $page.url.pathname;

  function showSidebar(segment: string) {
    if (!segment) return false;
    const allowedPaths = ["/dashboard", "/guide", "/policy"];
    return allowedPaths.some((path) => segment.startsWith(path));
  }

  function toggle() {
    dispatch("press");
  }

  function login() {
    return redirectToLogin({ error: false });
  }

  async function logout() {
    await fetch("/auth/logout", { credentials: "include" });
    userPromise.set(Promise.resolve(null));
    window.location.href = "/";
  }
</script>

<nav class="topnav navbar-expand navbar navbar-expand-md bg-primary">
  <a class="navbar-brand" href="/" data-sveltekit-reload="">
    <img alt="Tupperbox Avatar" src="/logo.svg" />
    <span class="d-none d-md-inline">{title}</span>
  </a>
  {#if showSidebar(segment)}
    <button
      class="btn-link order-0 order-lg-0 ps-2 pe-2 btn btn-sm"
      id="sidebar-toggle"
      on:click={toggle}
      type="button"
    >
      <i class="fas fa-bars fa-lg m-0" />
    </button>
  {/if}
  <a class="btn tiny-remove" href="/guide/basics">
    <i class="fas fa-book" /><span class="d-none d-md-inline">Guide</span>
  </a>
  <a class="btn tiny-remove" href="/premium">
    <i class="fas fa-crown orang" />
    <span class="d-none d-md-inline">Premium</span>
  </a>
  <a class="btn tiny-remove" href="/dashboard/list">
    <i class="fas fa-cog" /><span class="d-none d-md-inline">Dashboard</span>
  </a>
  <ul class="navbar-nav">
    <li class="dropdown nav-item">
      <button
        class="dropdown-toggle btn nav-link px-2"
        type="button"
        data-bs-toggle="dropdown">More</button
      >
      <ul class="dropdown-menu" data-bs-popper="static">
        <li class="tiny-add">
          <a class="dropdown-item" href="/guide/basics">
            <i class="fas fa-book" /><span>Guide</span>
          </a>
        </li>
        <li class="tiny-add">
          <a class="dropdown-item" href="/premium">
            <i class="fas fa-crown orang" /><span>Premium</span>
          </a>
        </li>
        <li class="tiny-add">
          <a class="dropdown-item" href="/dashboard/list">
            <i class="fas fa-cog" /><span>Dashboard</span>
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="/blog">
            <i class="fas fa-newspaper" /><span>Blog</span>
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="https://discord.gg/Z4BHccHhy3">
            <i class="fab fa-discord" /><span>Support</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <ul class="navbar-nav ms-auto">
    <li class="dropdown nav-item">
      {#await $userPromise}
        <div class="mx-4">
          <Spinner size="med" noFlex />
        </div>
      {:then user}
        {#if user}
          <button
            class="dropdown-toggle btn"
            data-bs-toggle="dropdown"
            type="button"
          >
            <img
              alt="{user.discord.global_name || user.discord.username}'s avatar"
              src={getUserAvatarURL(user.discord)}
              class="rounded-circle avatar-user"
            />
            <span class="d-none d-md-inline">
              {user.discord.global_name || user.discord.username}
            </span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item" href="/dashboard/list">Tuppers</a>
            </li>
            <li>
              <a class="dropdown-item" href="/dashboard/premium">Premium</a>
            </li>
            <li>
              <a class="dropdown-item" href="/dashboard/user">Configure Bot</a>
            </li>
            <li><div class="dropdown-divider" /></li>
            <li>
              <button class="dropdown-item" on:click={logout} type="button">
                Logout
              </button>
            </li>
          </ul>
        {:else}
          <button
            class="account-button btn me-2"
            type="button"
            on:click={login}
          >
            Login
          </button>
        {/if}
      {/await}
    </li>
  </ul>
</nav>

<style lang="scss">
  .account-button {
    background-color: darken($primary, 13%);
    &:hover {
      background-color: darken($primary, 15%);
    }
  }

  .topnav {
    .navbar-brand {
      border-radius: 1rem;
      padding-left: 0;
      padding-right: 0.75rem;
      color: $white;
      img {
        width: 40px;
        height: 40px;
      }
    }
    &.navbar {
      #sidebar-toggle {
        color: fade-out($white, 0.5);
      }
      .nav-link {
        color: $white;
      }
    }
    .avatar-user {
      width: 40px;
      height: 40px;
    }
  }
  a,
  button {
    margin: 2px;
    padding: calc(0.5rem - 2px);
  }
  a:focus,
  button:focus {
    outline: 2px solid $white;
  }
  .tiny-remove {
    display: none;
    @include screen-tiny {
      display: block;
    }
  }
  .tiny-add {
    display: block;
    @include screen-tiny {
      display: none;
    }
  }
</style>
